#container-dashboard {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
}

.dashboard-title h1 {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.recent-attacks-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
}

.recent-attacks-table th {
  text-align: left;
  background-color: var(--primary-color);
  padding: 12px;
}

.recent-attacks-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.recent-attacks-table tbody tr:hover {
  background-color: rgba(52, 152, 219, 0.1);
}

.react-datepicker__input-container input {
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  padding: 10px;
  width: 100%;
  font-size: 14px;
  transition: border-color 0.3s ease-in-out;
}

.react-datepicker__input-container input:focus {
  border-color: var(--accent-color);
  outline: none;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.highcharts-root {
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  margin-bottom: 20px;
}

footer {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  color: var(--primary-color);
}

footer a {
  color: var(--accent-color);
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Responsive */
@media (max-width: 768px) {
  #container-dashboard {
    padding: 15px;
  }

  .dashboard-title h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  .recent-attacks-table th, .recent-attacks-table td {
    padding: 8px;
  }
}