.container.group-details {
  font-family: 'Fira Code', monospace;
  margin: 40px auto;
  padding: 20px;
  border-radius: 12px;
}

.back-button {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.back-button:hover {
  background-color: #343a40;
}

h1, h2, p {
  color: #333;
}

h1 {
  font-size: 26px;
  margin-bottom: 10px;
}

h2 {
  font-size: 22px;
  margin-top: 30px;
}

.locations-table,
.attacks-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;
  border: 1px solid #343434;
}

.search-input {
  height: 50px; /* Igual que el botón */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Evita que padding afecte el tamaño */
  flex: 1; /* Ocupará el espacio restante si es necesario */
}

.download-button {
  height: 50px;
  padding: 0 20px; /* Espaciado interno para el botón */
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #2980b9;
}

.container-flex {
  display: flex;
  align-items: center; /* Alinear verticalmente ambos elementos */
  gap: 10px; /* Espaciado entre elementos */
  margin-bottom: 20px; /* Espaciado inferior opcional */
}
